.wrapper {
    padding: .5rem;
    padding-top: 100px;
    width: 100%;
    min-width: 768px;
    max-width: 1366px;
    margin: 0 auto;


    .inputBox {
        width: 30rem;
        float: left;
    }

    .deletedItems {
        line-height: 38px;
        height: 40px;

        &.highlight {
            color: red;
        }
    }

    .updaterControlsCard {
        background-color: var(--grey-50);
        margin-bottom: 1.5rem;
        padding: 0 1rem 0 1rem;
        height: 4rem;
        display: flex;
        flex-direction: row;
        align-content: center;
        flex-wrap: wrap;
    }

    .updaterControlsButtons {
        margin-left: auto;

        .button {
            margin-left: 1rem;
        }
    }
}

.editForm {
    min-height: 200px;
    > :not(:last-child) {
        margin-bottom: 1.25rem;
    }
}

.formField {
    width: 30rem;
    float: left;
    margin-bottom: 1.5rem;
}

.editModal {
    min-width: 80rem !important;
    max-height: calc(100vh - 10rem) !important;

    .editModalBody {
        .pmsPanel {
            margin-top: 10px;
            border: 1px solid var(--grey-100);
            border-radius: 8px;
            .pmsTableFilters {
                padding: 10px;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                span {
                    font-weight: 600;
                }
                column-gap: 0.5rem;
                :last-child {
                    width: 9rem;
                }
            }
            .pmsTableWrapper {
                height: calc(100vh - 440px - 100px - 30px - 10rem);
                border-top: 1px solid var(--grey-100);
                overflow: auto;
                .pmsTable {
                    .pmsTableHead {
                        font-weight: 600;
                    }
                    thead {
                        th:first-child {
                            width: 12rem;
                            button {
                                max-width: fit-content;
                            }
                        }
                        th:nth-last-child(2) {
                            max-width: 11rem;
                            width: 11rem;
                            text-align: right !important;
                        }
                        th:last-child {
                            width: 5.5rem;
                        }
                    }
                    tbody {
                        button {
                            padding: 0 !important;
                            height: 16px !important;
                        }
                        tr {
                            height: 16px;
                        }
                        td {
                            padding: 16px 12px;
                        }
                        td:nth-last-child(2) {
                            text-align: right;
                        }
                        td:last-child {
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
}

.item {
    border: 1px solid var(--grey-100);
    box-sizing: border-box;
    border-radius: 8px;
    user-select: none;

    /* center align text */
    display: flex;
    justify-content: left;
    align-items: center;

    &.hasDeletedChildren {
        border-right: 2px solid red;
    }

    &.hasAddedChildren {
        border-left: 2px solid MediumSeaGreen;
    }

    &.isNew {
        background-color: var(--primary-100);
    }

    &.isDeleted {
        background-color: var(--grey-100);
    }

    .content {
        font-weight: 500;
        font-size: 12px;
        font-family: Inter;
        color: black;
        user-select: text;

        display: flex;
        justify-content: left;
        align-items: center;
        width: 100%;

        &.plain {
            font-weight: 300;
        }

        .chevron {
            width: 48px;
        }

        .controls {
            margin-right: 0;
            margin-left: auto;
            display: flex;
            align-items: center;

            &.noDelete {
                padding-right: 96px;
            }
        }

        &.isAdding {
            box-shadow: 0px 0px 2px rgb(8, 58, 30), 0px 0px 10px MediumSeaGreen;
        }

        &.isEditing {
            box-shadow: 0px 0px 2px rgb(8, 58, 30), 0px 0px 10px red;
        }

        &.isDragging {
            background: #515b7d;
            border-color: MediumSeaGreen;
            box-shadow: 0px 0px 2px rgb(8, 58, 30), 0px 0px 10px MediumSeaGreen;

            &.notavailable {
                border-color: red;
                background: pink;
            }
        }
    }
}