.flashBg {
    animation: colorChange 2s infinite;
}

@keyframes colorChange {
    0% {
        background-color: white;
    }

    50% {
        background-color: var(--primary-75);
    }

    100% {
        background-color: white;
    }
}

.snapshotDate {
    margin-right: 1rem;
    color: var(--grey-400);
    font-size: .75rem;
}

.noDataPane {
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;
    width: fit-content;
    left: 50%;
    top: 3em;
    transform: translateX(-50%);
    padding: 5em 10em;

    border: 1px solid var(--grey-100);
}

.accountLink {
    text-decoration: none;
    color: black;

    &:hover {
        text-decoration: underline;
        color: var(--primary-400) !important;
        // font-weight: 500;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .header {
        height: 4em;
        width: 100%;
        border: 1px solid var(--grey-100);
        border-bottom: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 0.5em;
        padding-right: 0.5em;

        h4 {
            margin: 0;
        }

        .headerButtons {
            margin-left: auto;
            margin-right: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: 0.5em;
        }
    }

    .container {
        height: calc(100% - 4em);

        .tableWrapper {

            table {

                border-top-left-radius: 0;
                border-top-right-radius: 0;

                th,
                td {
                    vertical-align: middle;
                    border-left: 1px solid var(--grey-50);
                }

                tr {
                    height: 5em;
                    max-height: 5em;
                }

                thead {

                    tr:first-child {
                        box-shadow: inset 0 -2px 0 var(--grey-200);
                        background-color: white;
                    }
                }

                .propertyCell {
                    padding: 10px 12px 10px 12px;
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    align-items: center;

                    span {
                        flex: 1 1 auto;
                    }

                    button {
                        flex: 0 1 2rem;
                        margin-left: auto;
                        margin-right: 0;
                        opacity: 0.5;
                    }

                    button:hover {
                        opacity: inherit;
                    }

                }
            }

        }
    }
}